.bottomBarImage {
    position: fixed;
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.column-title {
    padding: 0.8vw 1.7vw;
    font-size: 2vw;
    font-weight: bolder;
    text-align: center;
    font-family: 'Oswald', serif !important;
}

.column-item {
    font-size: 2vw;
    font-weight: bolder;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Oswald', serif !important;
}

.name-styles {
    text-align: left;
}

.rank-styles {
    text-align: center;
}

.scan-to-play-text {
    font-size: 2vw;
    font-weight: bolder;
    font-family: 'Oswald', serif !important;
}

.loading-screen{
    background: rgba(0,0,0,.5) url('../images/ajax-loader.gif') center no-repeat;
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:99999;
}

@media (max-width: 768px) {
    .column-title {
        font-size: 5vw;
    }
    .column-item {
        font-size: 5vw;
    }
}
